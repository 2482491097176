// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import { _socials } from 'src/_mock/arrays';
import { useAppSelector } from 'src/redux/store';
import { FirebaseTypes } from 'src/types/Firebase';
import { getSocialSettings } from './socials';
import { ISocialIcon } from './types';



/**
 * Convert the links set by the reseller to the social icons to be used as buttons
 */
const convertResellerSocialSettings = (socialLinks: FirebaseTypes.Reseller.Wallet.Components.Footer.ISocialLinks) => {

  const icons: ISocialIcon[] = [];

  Object.keys(socialLinks).forEach(key => {
    const name = key;
    const url = socialLinks[key];
    const settings = getSocialSettings(name, url);
    if (settings) {
      icons.push(settings);
    } else {
      console.log(`Settings for social named ${name} not found`);
    }
  });

  return icons;
}


export default function Footer() {
  const { isPopUpBlocked } = useAppSelector(state => state.user);

  const footerSettings = useAppSelector(state => state.resellerSettings.components.footer);
  const { text1, text2 } = footerSettings;
  // const simpleFooter = (
  //   <Box
  //     component="footer"
  //     sx={{
  //       py: 5,
  //       textAlign: 'center',
  //       position: 'relative',
  //       bgcolor: 'background.default',
  //     }}
  //   >
  //     <Container>
  //       <Logo sx={{ mb: 1, mx: 'auto' }} />

  //       <Typography variant="caption" component="div">
  //         © All rights reserved
  //         <br /> made by
  //         <Link href="https://minimals.cc/"> minimals.cc </Link>
  //       </Typography>
  //     </Container>
  //   </Box>
  // );

  const icons = convertResellerSocialSettings(footerSettings.socialLinks);

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      {(text1 || text2 || icons.length > 0) && <Divider />}

      <Container
        sx={{
          pt: 5,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        {/* <Logo sx={{ mb: 3 }} /> */}

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          {/* Left Text */}
          <Grid xs={12} md={4} pt={1}>
            <Typography
              variant="body2"
              sx={{
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              {text1}
            </Typography>

          </Grid>
          <Grid xs={12} md={4} mt={{ xs: 2, md: 0 }}>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'center' }}
              alignItems="center"
              sx={{
                mb: { xs: 5, md: 0 },
              }}
              spacing={0.5}
            >
              {icons.filter(i => i.path).map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                >
                  <Link target={isPopUpBlocked ? "_top" : "_blank"} href={social.path}><Iconify color={social.color} icon={social.icon} /></Link>
                </IconButton>
              ))}
            </Stack>

          </Grid>
          <Grid xs={12} md={4} pt={1}>
            <Typography textAlign={{ xs: 'center', md: 'right' }} variant="body2" >
              {text2}
            </Typography>

          </Grid>

        </Grid>

      </Container>
    </Box>
  );

  return mainFooter;
}
