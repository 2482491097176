import type { ButtonProps } from '@mui/material/Button';
import type { Theme, SxProps } from '@mui/material/styles';

import { useCallback } from 'react';

import Button from '@mui/material/Button';
import { useAppSelector } from 'src/redux/store';
import { useSnackbar } from 'src/components/snackbar';
import { useAppDispatch } from 'src/redux/hooks';
import { logout } from 'src/redux/slices/authSlice';
import { PATH_AUTH } from 'src/routes/paths';
import { useNavigate } from 'react-router-dom';
import { resetApiState } from 'src/api/baseWebServer';


// ----------------------------------------------------------------------


type Props = ButtonProps & {
  sx?: SxProps<Theme>;
  onClose: () => void;
};

export function SignOutButton({ onClose, ...other }: Props) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user } = useAppSelector(store => store.auth);

  const { enqueueSnackbar } = useSnackbar();


  const handleLogout = useCallback(async () => {
    try {
      await dispatch(logout());
      dispatch(resetApiState());
      navigate(PATH_AUTH.login, { replace: true });
      onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });

      onClose();
    }

  }, [dispatch, enqueueSnackbar, navigate, onClose]);


  return (
    <Button
      fullWidth
      variant="soft"
      size="large"
      color="error"
      onClick={handleLogout}
      {...other}
    >
      Logout
    </Button>
  );
}
