import errorsFile from '../assets/errors.json';

const errors = JSON.parse(JSON.stringify(errorsFile));

export class ErrorStatus {
    errorCode: number;

    errorMessage: string;

    constructor(errorCode: number, errorMessage: string) {
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }

}


export enum ErrorOrigin {
    Firebase = "Firebase",
    Giftoin = "Giftoin",
    Redux = "Redux",
    Unknown = "Unknown",
}



/**
 * 
 * @returns 
 */
function convertFirebaseErrorMessage(error: any) {
    const errorCode = error.code;
    if (errorCode === 'auth/email-already-in-use') {
        return 'User Already Exists';
    };

    if (errorCode === 'auth/weak-password') {
        return 'Weak password - minimum of 6 characters ';
    };
    if (errorCode === 'auth/user-not-found') {
        return 'Incorrect email/password';
    }

    if (errorCode === 'auth/wrong-password') {
        return 'Incorrect email/password';
    }
    if (errorCode === 'auth/too-many-requests') {
        return 'Too many requests. Please try again later.';
    }

    switch (errorCode) {
        case "auth/invalid-verification-code":
            return 'Invalid Verification Code'
        default:
            return errorCode.replace("auth/", "");
    }

}



const checkIfErrorObject = (error: any) => {
    if (error instanceof Error) return true;

    if (error && error.stack && error.message) {
        // it's an error, probably
        return true
    }
    return false;
}

const checkErrorOrigin = (error: any) => {
    // Check if the error is from our backend 
    if (error.errorCode || error?.data?.status?.errorCode) {
        return ErrorOrigin.Giftoin;
    }
    // Check if the error is from firebase services

    if (error.code) {
        return ErrorOrigin.Firebase;
    }
    // Check if the error is from redux

    if (error?.error?.status) {
        return ErrorOrigin.Redux;
    }

    // End Case - Error 500

    return ErrorOrigin.Unknown;
}


/**
 * Given any kind of error and translate it to a message we can present to the user
 */
export const handleError = (error: any) => {
    const errorOrigin = checkErrorOrigin(error);
    switch (errorOrigin) {
        case ErrorOrigin.Giftoin: {
            const errorStatus = extractStatusObjectFromError(error);
            return errorStatus;
        }
        case ErrorOrigin.Firebase: {
            return convertFirebaseErrorMessage(error);
        }
        case ErrorOrigin.Redux: {
            return convertFirebaseErrorMessage(error);
        }
        case ErrorOrigin.Unknown: {
            if (checkIfErrorObject(error)) {
                return error?.message as string;
            }
            if (error?.error?.status) {
                if (error?.error?.status === 'FETCH_ERROR') {
                    return "Network error - Please try again later";
                }
            }
            return "Unknown error - Please contact support";
        }

        default: {
            if (checkIfErrorObject(error)) {
                return error?.message as string;
            }
            return "Unknown error - Please contact support";
        }
    }


}




export const extractStatusObjectFromError = (res: any) => {

    const errorCode = res?.data?.status?.errorCode ?? 500;

    let message = "";

    if (errorCode === 400) {
        message = res?.data?.status?.errorMessage;
    }

    if (!message) {
        const error = errors[errorCode];
        message = error ? error.message ?? "Unknown Error" : "Unknown Error";
    }




    return new ErrorStatus(errorCode, message);
}


// export const extractError = (err: any) => {
//     // Error from api request to our services
//     if (err?.data.status?.errorCode) {
//         return extractStatusObjectFromReduxResponse(err);
//     }

//     return JSON.stringify(err);
// }